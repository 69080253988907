@import "~@/erp/styles/variables/variables.scss";

















































































































































































































































































































































































































































































































































































































































































































































































































































































.page-content {
  width: 100%;

  .page_title {
    height: 50px;
    line-height: 50px;
    background-color: #f2f2f2;
    width: 100%;
    padding-left: 10px;
  }

  .cot {
    box-sizing: border-box;
    padding: 20px;

    .item-title {
      line-height: 40px;
      display: block;
      height: 40px;
    }
  }
}
