@import "~@/erp/styles/variables/variables.scss";



















































































































































.page-content {
  width: 100%;
}
.flex-warp {
  word-break: break-all;
}
