@import "~@/erp/styles/variables/variables.scss";















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.product-info {
  margin-bottom: 50px;

  p {
    line-height: 20px;
    text-align: justify;
  }

  .img-main {
    max-width: 200px;
    max-height: 200px;
    border-radius: 2px;
    margin-left: 10px;
  }

  .img-swiper {
    max-width: 100px;
    max-height: 100px;
    border-radius: 2px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.test {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  p {
    word-wrap: break-word !important;
  }
}

.border-line {
  padding-bottom: 15px;
  border-bottom: 1px solid #ededed;
}

.product_info_table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    height: 38px;
    text-align: center;
    border: 1px solid #ebeef5;
  }

  th {
    font-weight: normal;
    background-color: #f5f7fa;
  }
}

.c_pointer.active {
  background-color: #e8f4ff;
  border-color: #d1e9ff;
  color: #1890ff;
}

.label-content {
  label {
    color: #000;
  }

  ::v-deep {
    .el-form-item--small.el-form-item {
      margin-bottom: 0;
      height: 32px;
      line-height: 32px !important;
      overflow: hidden;
    }
  }
}

.label-text {
  max-width: 100px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label-row {
  flex-wrap: wrap;

  p {
    margin-bottom: 10px;
    margin-right: 20px;

    span {
      color: #909399;

      i {
        font-style: normal;
        display: inline-block;
        margin-right: 7px;
      }
    }
  }
}

.pro-base-msg {
  label {
    width: 90px;
    display: inline-block;
    color: #000;
  }

  .special-label {
    width: 130px;
  }

  span {
    width: calc(100% - 90px);
    padding-right: 20px;
    display: inline-block;
  }

  .tooltip-span {
    width: calc(100% - 90px);
    padding-right: 20px;
    display: inline-block;
  }
}

.custom-table {
  overflow: auto;

  p + p {
    margin-top: 3px;
  }
}

.tooltip-content {
  max-height: 350px;
  overflow: hidden;
  overflow-y: auto;
  padding: 15px;

  p {
    font-size: 14px;
  }

  p + p {
    margin-top: 8px;
  }
}

.custom-table {
  td {
    .row-charge {
      height: 115px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}

.testData-row {
  max-height: 370px;
  overflow-y: auto;
}

.no-wrap {
  white-space: nowrap;
}

.delivery-period {
  p {
    text-align: center;
  }
}
.images {
  position: absolute;
  right: 0;
  // z-index: 9999;
  z-index: 10; // 层级不能过高，不能超过Dialog的层级
}
