@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































































































































































































































































































































































































































































.print-pricing {
  margin: 0 0 30px;
  .left-h6 {
    display: flex;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }

  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }

  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px;
    border-radius: 3px;
  }
  .content {
    height: 100%;
    border: 1px solid #e4e7ed;
    border-radius: 3px;
    .content-head {
      height: 50px;
      line-height: 50px;
      background-color: #f2f2f2;
      width: 100%;
      padding-left: 10px;
    }
    .panel-item {
      padding: 20px 10px 0;
    }
    .panle-item-title {
      padding: 10px 20px;
      .left-h6 {
        line-height: 30px;
        margin-bottom: 20px;
        &::before {
          content: '';
          display: block;
          height: 26px;
          width: 4px;
          background: rgba(2, 125, 180, 1);
          margin-right: 5px;
        }
      }
      .outnumber-panle {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        ::v-deep .el-input {
          width: 100px;
          margin: 0 10px;
        }
      }
      .outnumber-panle-other {
        margin-bottom: 20px;
        ::v-deep .el-input {
          width: 100px;
          margin: 0 10px;
        }
      }
    }
  }
  ::v-deep {
    .el-table {
      .el-table__body td {
        height: 86px;
        .el-form-item {
          margin: 18px 0;
        }
      }
    }
  }
}
.required-symbol {
  &::before {
    content: '*';
    color: #ff4d4f;
  }
}
.profit-rate {
  &::after {
    content: '%';
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .el-input__inner {
    padding: 0 18px 0 15px;
  }
}
.warn-demo {
  padding: 3px;
  border: 1px solid rgba(217, 0, 27, 1);
  background-color: rgba(217, 0, 27, 0.09803921568627451);
}
.select-box {
  display: flex;
  align-items: center;
  ::v-deep.el-tooltip {
    margin-left: 2px;
  }
}
