@import "~@/erp/styles/variables/variables.scss";











































































































































































































































































































::v-deep {
  .el-checkbox__input.is-disabled + span.el-checkbox__label,
  .el-radio__input.is-disabled + span.el-radio__label {
    color: #666 !important;
  }
  .el-radio {
    line-height: 32px;
  }
}
