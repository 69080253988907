@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































































































































































































































































































































































































































































































.calcu-p {
  font-size: 12px;
  color: #999;
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
.el-dialog-div {
  height: 60vh;
  overflow: hidden;
  overflow-y: auto;
}
::v-deep {
  .el-dialog-div {
    .el-form {
      width: 95% !important;
      margin: 0 auto !important;
    }
    .el-input-group__append,
    .el-input-group__prepend {
      padding: 0 10px;
    }
    .append-select {
      color: rgba(0, 0, 0, 0.65);
      height: 34px;
      border: none;
      background: #f5f7fa;
      cursor: pointer;
    }
  }
}
